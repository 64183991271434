import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import logo from "../assets/logo3.png";

interface AppNavbarProps {
  selectedView: string;
  handleViewChange: (view: string) => void;
  visible?: boolean;
}

const AppNavbar: React.FC<AppNavbarProps> = ({
  selectedView,
  handleViewChange,
  visible = true,
}) => {
  const userIcon = faUser as IconProp;

  return (
    <Navbar expand="lg" className="bg-light" >
      <Container style={{ marginLeft: "10px", maxWidth: "100%" }}>
        <Navbar.Brand href="#home">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        {visible && (
          <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <NavDropdown title={selectedView} id="basic-nav-dropdown">
                  <NavDropdown.Item
                    href="#schedule"
                    onClick={() => handleViewChange("schedule")}
                  >
                    Schedule
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#group"
                    onClick={() => handleViewChange("group")}
                  >
                    Group
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="#client"
                    onClick={() => handleViewChange("client")}
                  >
                    Client
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
        <Nav.Link href="#home" className="text-dark pull-right">      
          <FontAwesomeIcon icon={userIcon} />
        </Nav.Link>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
