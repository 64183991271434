import { MsalConfig } from "../interfaces/contracts";

export const msalConfig: MsalConfig = {
  auth: {
    /*
    clientId: "4d675a4b-d1b3-4b42-b23a-47bfc391538b", // cognitivehealth client id
    authority:"https://login.microsoftonline.com/c5afe569-7ec5-4e04-9ac5-641f77070af5", // cognitivehealth  tenant ID
     //redirectUri: "https://comchsweb.azurewebsites.net",
    redirectUri: "http://localhost:5173",
    */
   clientId: "6c1f1eb7-95b4-4e18-bd38-069a6ede1cd5", // Hammond client id
    authority:"https://login.microsoftonline.com/ba16567c-026d-478d-957e-68bdf3db5530", // Hammond  tenant ID
    redirectUri: "https://app.cognitivehealth.com.au",
   
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  //scopes: ["api://be6c8b22-c447-4e13-ba62-1a70dcb95310/.default"], // cognitivehealth Scope
  scopes: ["api://e53fe8a0-df5a-4e84-a6e6-002a3b9554aa/.default"], // Hammond Scope
};
