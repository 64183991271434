import { Modal, Button } from "react-bootstrap";
import React from "react";
import { RowData } from "../interfaces/contracts";

interface ConfirmCallModalProps {
    showConfirmModal: boolean;
  rowData: RowData;
  selectedView: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmCallModal: React.FC<ConfirmCallModalProps> = ({
    showConfirmModal,
  rowData,
  selectedView,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal show={showConfirmModal} onHide={onCancel} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Call</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <h5>Are you sure you want to call {rowData.name}?</h5>
          <p>
            Appointment Type:{" "}
            {selectedView === "client" ? "Adhoc Meeting" : rowData.appointmentType}
          </p>
          <p>Phone: {rowData.clientMobileNumber}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          No
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmCallModal;
