import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import logo from "../assets/logo3.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  return (
    <Container fluid className="vh-100 d-flex align-items-center justify-content-center" style={{ backgroundColor: "#f8f9fa" }}>
      <Row>
        <Col>
          <Card style={{ minWidth: "300px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
            <Card.Body className="text-center p-5">
              <img
                src={logo}
                alt="Company Logo"
                className="mb-4"
                style={{ maxWidth: "200px" }}
              />
              <h2 className="mb-4">Welcome Back</h2>
              <p className="text-muted mb-4">
                Please sign in to access your account
              </p>
              <Button
                variant="outline-primary"
                size="lg"
                className="w-100"
                onClick={handleLogin}
              >
                <FontAwesomeIcon  icon={faMicrosoft} className="me-2" />
                Sign in with Microsoft
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
