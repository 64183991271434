import { CustomButton } from "./CustomButton";

export const formattedDate = (rowDate: string | number) => {
  const date = new Date(rowDate.toLocaleString());
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "short",
    hour12: false,
  });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day} ${month} ${year}, ${hours}:${minutes}`;
};

export const getValueForClientStatusColumn = (
  handleOpenModal: (showModal: boolean) => void,
  cellValue: string | number,
  handleCallButtonClick?: () => void
) => {
  if (cellValue.toString().toUpperCase() == "CALL") {
    return (
      <CustomButton
        onClick={handleCallButtonClick}
        variant="success"
        buttonText={cellValue}
        disabledFlag={false}
      />
    );
  } else if (cellValue.toString().toUpperCase() == "BUSY") {
    return (
      <CustomButton
        variant="danger"
        buttonText={cellValue}
        disabledFlag={true}
      />
    );
  } 
  else if (cellValue.toString().toUpperCase() == "JOIN") {
    return (
      <CustomButton
        onClick={() => handleOpenModal(true)}
        variant="primary"
        buttonText={cellValue}
        disabledFlag={false}
      />
    );
  } 
  else {
    return (
      <CustomButton
        variant="secondary"
        buttonText={cellValue}
        disabledFlag={false}
      />
    );
  }
};
